import { format } from 'date-fns'
import { Competition } from '../_app/types/firestore.ts'
import {
  getCompetitionTranslatedDescription,
  getCompetitionTranslatedName,
  useGetUserTimeLocale,
  useT,
  useUserLocale,
} from '../_app/translations/i18nUtils.tsx'
import { useRoutes } from '../_app/constants/routes.ts'
import { getCategoriesFromCompetitionRuns } from '../_app/utils/utils.ts'
import SizeChips from '../_app/components/sizeChips.tsx'
import RoundedImage from '../_app/components/roundedImage.tsx'
import Calendar from '../_app/components/icons/calendar.tsx'
import { deserializeCompetition, getIsUserParticipating } from '../_utils'
import { isRegistrationOpen } from '../_app/utils'
import Linked from '@components/linked.tsx'

type Props = {
  serializedCompetition: Competition
  dogIds: number[]
}

export default function CompetitionCard({ serializedCompetition, dogIds }: Props) {
  const props = deserializeCompetition(serializedCompetition)
  const { id, photo, dates, submitOpenDate, submitClose, locationName } = props
  const sizes = getCategoriesFromCompetitionRuns(props)
  const t = useT()
  const timeLocale = useGetUserTimeLocale()
  const locale = useUserLocale()

  const routes = useRoutes()
  const { isUserParticipating, isAwaitingApproval } = getIsUserParticipating(
    deserializeCompetition(serializedCompetition),
    dogIds,
  )

  return (
    <div className="h-full w-full p-4">
      <div className="flex h-full flex-1 flex-col">
        <div className="flex h-full flex-1 flex-col ">
          <div className="flex items-center justify-between">
            <RoundedImage
              enableRoundiness={false}
              src={photo}
              size={160}
              alt={getCompetitionTranslatedName(props, locale)}
              enableGallery
            />

            <div className="flex flex-col text-right ">
              <div className="flex gap-2 items-center justify-end">
                <Calendar />
                <div className="flex flex-col">
                  {dates.map(date => (
                    <span key={date.toString()}>
                      {format(date.toDate(), 'd MMMM, yyyy', {
                        locale: timeLocale,
                      })}
                    </span>
                  ))}
                </div>
              </div>

              <div className="mt-2">
                <span>
                  {isUserParticipating ? (
                    <span data-cy="competition.youAreParticipating" className="text-green-800">
                      {t('home.youAreParticipating')}
                    </span>
                  ) : isAwaitingApproval ? (
                    t('home.awaitingApproval')
                  ) : isRegistrationOpen(submitOpenDate.toDate(), submitClose.toDate()) ? (
                    <span className="font-bold text-green-800">{t('home.registrationOpen')}</span>
                  ) : null}
                </span>
              </div>
            </div>
          </div>

          <div className="flex">
            <a href={routes.competition(id)} className="my-8 ">
              <h2 className="m-0 p-1 text-xl font-medium underline" data-cy="competitionCard.name">
                {getCompetitionTranslatedName(props, locale)}
              </h2>
            </a>
          </div>
          <div className="align-center flex gap-2">
            <SizeChips sizes={sizes} />
          </div>

          <h3 className="text-md font-normal">{locationName}</h3>

          <span className="line-clamp-6">
            <Linked text={getCompetitionTranslatedDescription(props, locale)} />
          </span>
        </div>
        <div>
          <div className="mt-8 flex w-full justify-end">
            <a href={routes.competition(id)}>
              <button data-cy="competition.checkIt" className="btn">
                {t('home.checkIt')}
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
